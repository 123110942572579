import React, { useState, useEffect } from 'react';
import './PinValidationModal.css';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { FIRESTORE_DB } from '../../firebaseConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { motion } from 'framer-motion';

import First from '../../assets/frst.svg';
import Plus from '../../assets/plus.svg';
import Vesjobs from '../../assets/vesjobs.svg';
import deleteIcon from '../../assets/backspace1.svg';

const PinValidationModal = ({ onValidate, onValidated }) => {
    const [pin, setPin] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        if (pin.length === 5) {
            handleValidate();
        }
    }, [pin]);

    const handlePinChange = (value) => {
        if (value === 'delete') {
            setPin(prevPin => prevPin.slice(0, -1)); 
        } else if (pin.length < 5) {
            setPin(prevPin => prevPin + value);
        }
    };

    const handleValidate = async () => {
        const leadsRef = collection(FIRESTORE_DB, 'leads');
        const q = query(leadsRef, where('pin', '==', pin));
    
        try {
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
                const doc = querySnapshot.docs[0];
                const userData = doc.data();
                const docId = doc.id;
                toast.success(`Usuário Autenticado, boa sorte ${userData.name}`);
                onValidate({ ...userData, id: docId });
                setTimeout(onValidated, 5000);
            } else {
                setError('PIN inválido. Tente novamente.');
                toast.error('PIN inválido. Tente novamente.');
                setPin('');
                setTimeout(() => setError(''), 5000); 
            }
        } catch (error) {
            console.error('Error validating PIN: ', error);
            setError('Erro ao validar PIN. Tente novamente.');
            toast.error('Erro ao validar PIN. Tente novamente.');
            setPin(''); 
            setTimeout(() => setError(''), 5000); 
        }
    };
    
    const NumericKeypad = () => {
        const buttons = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'delete']; 
        return (
            <div className="keypad">
                {buttons.map(button => (
                    <button
                        key={button}
                        onClick={() => handlePinChange(button)}
                        className={`keypad-button ${button === 'delete' ? 'delete-button' : ''}`} 
                    >
                        {button === 'delete' ? <img src={deleteIcon} alt="delete icon" className="delete-icon" /> : button} {/* Imagem 'deleteIcon' para o botão de delete */}
                    </button>
                ))}
            </div>
        );
    };

    const PinDisplay = () => {
        const variants = {
            hidden: { opacity: 0, y: 20 },
            visible: { opacity: 1, y: 0 }
        };
    
        return (
            <div className="pin-display">
                {Array.from({ length: 5 }).map((_, index) => (
                    <motion.div
                        key={index}
                        className="pin-square"
                        transition={{ duration: 0.5 }}
                    >
                        <motion.div
                            key={index}
                            variants={variants}
                            initial="hidden"
                            animate={index < pin.length ? "visible" : "hidden"}
                            transition={{ duration: 0.5 }}
                        >
                            {index < pin.length ? pin[index] : ''}
                        </motion.div>
                    </motion.div>
                ))}
            </div>
        );
    };

    return (
        <motion.div
            className="modal-background"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1.4 } }}
        >
            <motion.div
                className="modal-content"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1.4 } }}
            >
                <div className="logo-container">
                    <div className="logo-container-pin">
                        <img src={First} alt="First logo" className="logo-part-pin" />
                        <img src={Plus} alt="Plus logo" className="logo-plus-pin" />
                        <img src={Vesjobs} alt="Vesjobs logo" className="logo-part-pin" />
                    </div>
                </div>
                <div className='cta-container'>
                    <h2 className='vamos-jogar'>Vamos jogar!</h2>
                    <p className='digite'> Digite o código recebido após o seu cadastro</p>
                    <PinDisplay />
                    {error && <p className="error-message">{error}</p>}
                    <button onClick={handleValidate} className="validate-button">COMEÇAR</button>
                </div>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="dark"
                />
            </motion.div>
            <div className='keypad-container'>
                <NumericKeypad />
            </div>
        </motion.div>
    );
};

export default PinValidationModal;

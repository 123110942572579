import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore'


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyATfzIkEp9mV_oZAffqmBOC8vhdtXKcVMc",
    authDomain: "vesjobs-memory-game.firebaseapp.com",
    projectId: "vesjobs-memory-game",
    storageBucket: "vesjobs-memory-game.appspot.com",
    messagingSenderId: "463059059443",
    appId: "1:463059059443:web:066210e86a83e55619ad86"
};

export const FIREBASE_APP = initializeApp(firebaseConfig)
export const FIRESTORE_DB = getFirestore(FIREBASE_APP)
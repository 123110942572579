import React, { useState } from 'react';
import './App.css';
import "./Animation.css";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import InitialPage from './pages/InitialPage';
import LeadCapture from './pages/LeadCapture/LeadCapture';
import Dashboard from './pages/Dashboard/DashBoard';
import VictoryModal from './components/Modals/Victory/VictoryModal';
import DefeatModal from './components/Modals/Defeat/DefeatModal';
import QrCode from './pages/QrCode';

function App() {
  const [leadData, setLeadData] = useState(null);

  const handleStartGame = (lead) => {
    setLeadData(lead);
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<InitialPage onStartGame={handleStartGame} />} />
        <Route path="/cadastro" element={<LeadCapture />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/qrcode" element={<QrCode />} />

      </Routes>
    </Router>
  );
}

export default App;

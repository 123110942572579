import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { FIRESTORE_DB } from '../../firebaseConfig';
import './Dashboard.css'; 
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from 'recharts';

const Dashboard = () => {
    const [leads, setLeads] = useState([]);
    const [peakTimes, setPeakTimes] = useState([]);
    const [groupedLeadsByDate, setGroupedLeadsByDate] = useState({});

    useEffect(() => {
        const fetchLeads = async () => {
            try {
                const querySnapshot = await getDocs(collection(FIRESTORE_DB, 'leads'));
                const leadsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setLeads(leadsData);

                const peakTimesCount = {};
                leadsData.forEach(lead => {
                    if (lead.registryMadeAt && lead.registryMadeAt.time) {
                        const hour = new Date(`1970-01-01T${lead.registryMadeAt.time}Z`).getUTCHours();
                        if (peakTimesCount[hour]) {
                            peakTimesCount[hour]++;
                        } else {
                            peakTimesCount[hour] = 1;
                        }
                    }
                });

                const peakTimesData = Object.entries(peakTimesCount).map(([hour, count]) => ({
                    hour: `${hour}:00`,
                    count
                })).sort((a, b) => parseInt(a.hour) - parseInt(b.hour));

                setPeakTimes(peakTimesData);

                // Group leads by date
                const leadsByDate = leadsData.reduce((acc, lead) => {
                    const date = lead.registryMadeAt?.date || 'Unknown';
                    if (!acc[date]) {
                        acc[date] = [];
                    }
                    acc[date].push(lead);
                    return acc;
                }, {});

                setGroupedLeadsByDate(leadsByDate);
            } catch (error) {
                console.error('Error fetching leads: ', error);
            }
        };

        fetchLeads();
    }, []);

    const formatDuration = (seconds) => {
        if (isNaN(seconds) || seconds === null || seconds === undefined) {
            return '0:00';
        }
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const totalLeads = leads.length;

    const totalVictories = leads.filter(lead => lead.gameResult === 'victory').length;
    const totalDefeats = leads.filter(lead => lead.gameResult === 'defeat').length;

    const averageVictories = totalLeads > 0 ? ((totalVictories / totalLeads) * 100).toFixed(2) : 0;
    const averageDefeats = totalLeads > 0 ? ((totalDefeats / totalLeads) * 100).toFixed(2) : 0;

    const averagePairsFound = totalLeads > 0 ? (leads.reduce((acc, lead) => acc + lead.pairsFound, 0) / totalLeads).toFixed(2) : 0;

    return (
        <div className="dashboard-container">
            <h1 className="dashboard-title">Dashboard de Leads</h1>
            <div className="statistics">
                <div className="stat">
                    <p className="stat-label">Total de Leads</p>
                    <p className="stat-value">{totalLeads}</p>
                </div>
                <div className="stat">
                    <p className="stat-label">Total de Vitórias</p>
                    <p className="stat-value">{totalVictories}</p>
                </div>
                <div className="stat">
                    <p className="stat-label">Total de Derrotas</p>
                    <p className="stat-value">{totalDefeats}</p>
                </div>
                <div className="stat">
                    <p className="stat-label">Média de Vitórias (%)</p>
                    <p className="stat-value">{averageVictories}</p>
                </div>
                <div className="stat">
                    <p className="stat-label">Média de Derrotas (%)</p>
                    <p className="stat-value">{averageDefeats}</p>
                </div>
                <div className="stat">
                    <p className="stat-label">Média de Pares Encontrados</p>
                    <p className="stat-value">{averagePairsFound}</p>
                </div>
            </div>
            <div className="chart-container">
                <h2 className="chart-title">Pico de Registros por Horário</h2>
                <ResponsiveContainer width="100%" height={400}>
                    <LineChart data={peakTimes}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hour" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="count" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div className="date-container">
                {Object.entries(groupedLeadsByDate).map(([date, leads]) => (
                    <div key={date} className="date-group">
                        <h3>{date}</h3>
                        <table className="leads-table">
                            <thead>
                                <tr>
                                    <th>Data de Registro</th>
                                    <th>Início da Partida</th>
                                    <th>Duração da Partida</th>
                                    <th>Início</th>
                                    <th>Status do Jogo</th>
                                    <th>Status da Jornada</th>
                                    <th>Pares Encontrados</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leads.map(lead => (
                                    <tr key={lead.id}>
                                        <td>{lead.registryMadeAt?.date}</td>
                                        <td>{lead.gameStartTime?.time}</td>
                                        <td>{formatDuration(lead.duration)}</td>
                                        <td>{lead.gameStartTime?.date}</td>
                                        <td>{lead.gameResult}</td>
                                        <td>{lead.status}</td>
                                        <td>{lead.pairsFound}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;

import React from 'react';
import qrcode from "../assets/qrcode.jpg";
import First from "../assets/frst.svg";
import Plus from "../assets/plus.svg";
import Vesjobs from "../assets/vesjobs.svg";

function QrCode() {
  return (
    <div style={{
      position: 'fixed', 
      top: '50%', 
      left: '50%', 
      transform: 'translate(-50%, -50%)',
      backgroundColor: 'white',
      padding: '40px',
      borderRadius: '15px',
      textAlign: 'center',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)'
    }}>
      <div className='logo-container'>
        <img src={First} alt="First logo" style={{ height: '50px', animation: 'glow 2s ease-in-out infinite alternate' }} />
        <img src={Plus} alt="Plus logo" style={{ height: '50px', animation: 'glow 3s ease-in-out infinite alternate' }} />
        <img src={Vesjobs} alt="Vesjobs logo" style={{ height: '50px', animation: 'glow 4s ease-in-out infinite alternate' }} />
      </div>
      <p style={{fontSize: '3rem'}}>Acesse o QR code para se cadastrar</p>
      <img src={qrcode} alt="QR Code" />
    </div>
  );
}

export default QrCode;

import React, { useState } from 'react';
import PinValidationModal from '../pages/Validation/PinValidation';
import GameBoard from '../pages/Game/GameBoard';
import "../App.css";

function InitialPage({ onStartGame }) {
    const [leadData, setLeadData] = useState(null);
    const [showGame, setShowGame] = useState(false);

    const handleStartGameWithLead = (lead) => {
        setLeadData(lead);
        onStartGame(lead);
    };

    const handleShowGame = () => {
        setShowGame(true);
    };

    return (
        <div className='h-screen flex flex-col justify-center items-center'>
            {!showGame ? (
                <PinValidationModal onValidate={handleStartGameWithLead} onValidated={handleShowGame} />
            ) : (
                <GameBoard leadData={leadData} />
            )}
        </div>
    );
}

export default InitialPage;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { collection, addDoc, updateDoc, doc, getDocs, query, where, Timestamp, onSnapshot } from 'firebase/firestore';
import { FIRESTORE_DB } from '../../firebaseConfig';
import { motion, AnimatePresence } from 'framer-motion';  // Import AnimatePresence for handling exit animations

import First from '../../assets/frst.svg';
import Plus from '../../assets/plus.svg';
import Vesjobs from '../../assets/vesjobs.svg';

import './LeadCapture.css';

const blockedDomains = [
    "gmail.com", "hotmail.com", "yahoo.com", "outlook.com", "aol.com", "live.com",
    "icloud.com", "mail.com", "terra.com", "bol.com.br", "uol.com.br", "globo.com", "ig.com.br"
];
const loadingScreenVariants = {
    hidden: {
        opacity: 0,
        backgroundColor: "#FD7A49"  // Laranja inicial
    },
    visible: {
        opacity: 1,
        backgroundColor: "#000000", // Transição para preto
        transition: {
            duration: 1.4,
            ease: "easeInOut",
            backgroundColor: {
                delay: 3.5,  // Delay para começar a mudança de cor após o elemento ser visível
                duration: 7  // Duração da transição de cor
            }
        }
    },
    exit: {
        opacity: 0,
        transition: { duration: 0.5 }
    }
};

const emailRegex = /^[^\s@]+@[^\s@]+\.(com|com\.br|org|net|gov|edu|mil|int)$/i;

function LeadCapture() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [company, setCompany] = useState("");
    const [companyTraineeProgram, setCompanyTraineeProgram] = useState("");
    const [pin, setPin] = useState("");
    const [emailError, setEmailError] = useState("");
    const [registryMadeAt, setRegistryMadeAt] = useState(null);
    const [gameStatus, setGameStatus] = useState({ status: "", duration: "", pairsFound: "", gameResult: "" });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        let unsubscribe = null;
        if (pin) {
            unsubscribe = fetchGameStatus(pin);
        }
        return () => {
            if (unsubscribe) {
                unsubscribe();
            }
        };
    }, [pin]);

    useEffect(() => {
        const storedPin = sessionStorage.getItem('pin');
        if (storedPin) {
            setPin(storedPin);
        }
    }, []);

    const generateRandomPIN = () => {
        return Math.floor(10000 + Math.random() * 90000).toString();
    };

    const isUniquePIN = async (newPin) => {
        const q = query(collection(FIRESTORE_DB, 'leads'), where('pin', '==', newPin));
        const querySnapshot = await getDocs(q);
        return querySnapshot.empty;
    };

    const generateUniquePIN = async () => {
        let newPin;
        let unique = false;

        while (!unique) {
            newPin = generateRandomPIN();
            unique = await isUniquePIN(newPin);
        }

        return newPin;
    };

    const fetchGameStatus = (pin) => {
        const q = query(collection(FIRESTORE_DB, 'leads'), where('pin', '==', pin));
        onSnapshot(q, (querySnapshot) => {
            if (!querySnapshot.empty) {
                const gameData = querySnapshot.docs[0].data();
                setGameStatus({
                    status: gameData.status,
                    duration: gameData.duration,
                    pairsFound: gameData.pairsFound,
                    gameResult: gameData.gameResult
                });
            } else {
                setGameStatus({ status: "not-started", duration: "", pairsFound: "", gameResult: "" });
            }
        }, (error) => {
            console.error("Error fetching game status: ", error);
        });
    };
    useEffect(() => {
        console.log("Game Status: ", gameStatus);
    }, [gameStatus]);

    useEffect(() => {
        if (gameStatus.status === 'finished') {
            setLoading(true);
            setTimeout(() => {
                setLoading(false);
            }, 10000);
        }
    }, [gameStatus.status]);

    const capitalize = (str) => {
        return str.replace(/\b\w/g, char => char.toUpperCase());
    };

    const handleNameChange = (e) => {
        let inputValue = e.target.value.replace(/[^A-Za-z ]/g, '');
        inputValue = capitalize(inputValue);
        setName(inputValue);
    };

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        const domain = emailValue.split('@')[1];

        if (!emailRegex.test(emailValue) || blockedDomains.includes(domain)) {
            setEmailError("Email inválido ou domínio não permitido.");
        } else {
            setEmailError("");
        }
        setEmail(emailValue);
    };

    const handleCompanyChange = (e) => {
        let inputValue = e.target.value.replace(/[^A-Za-z ]/g, '');
        inputValue = capitalize(inputValue);
        setCompany(inputValue);
    };


    const handleTraineeProgramChange = (e) => {
        setCompanyTraineeProgram(e.target.value);
    };

    const handleStartGame = async () => {
        if (name && email && company && companyTraineeProgram && !emailError) {
            const lead = { name, email, company, companyTraineeProgram };
            const now = new Date();
            const formattedDate = `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`;
            const formattedTime = `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`;
            const registryMadeAt = {
                date: formattedDate,
                time: formattedTime
            };

            try {
                const docRef = await addDoc(collection(FIRESTORE_DB, 'leads'), {
                    ...lead,
                    registryMadeAt: registryMadeAt,
                    status: 'not-started'
                });

                console.log('Lead added with ID: ', docRef.id);

                const newPin = await generateUniquePIN();
                await updateDoc(doc(FIRESTORE_DB, 'leads', docRef.id), { pin: newPin });

                setPin(newPin);
                sessionStorage.setItem('pin', newPin); // Salva o PIN no sessionStorage
                toast.success(`Dados salvos com sucesso! Seu PIN é: ${newPin}`);
            } catch (error) {
                console.error('Error adding lead: ', error);
                toast.error("Erro ao salvar os dados!");
            }
        } else {
            if (emailError) {
                toast.warn(emailError);
            } else {
                toast.warn("Preencha todos os campos!");
            }
        }
    };
    const handleLeaveQueue = () => {
        sessionStorage.removeItem('pin');
        window.location.reload();
    };

    return (
        <motion.div
            className="lead-modal-overlay"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1, transition: { duration: 1.4 } }}
        >
            <motion.div
                className="lead-modal"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { duration: 1.4 } }}
            >      <motion.div className="logo-container">
                    <img src={First} alt="First logo" className="logo-part animate glow delay-2" />
                    <img src={Plus} alt="Plus logo" className="logo-plus animate glow delay-3" />
                    <img src={Vesjobs} alt="Vesjobs logo" className="logo-part animate glow delay-4" />
                </motion.div >
                <h2 className="message animate pop delay-5">Vamos jogar!</h2>
                {pin ? (
                    <div className="pin-button-message">
                        <p><span className="animate fade delay-2">Seu PIN: </span><span className="animate fade delay-3">{pin}</span></p>
                        {gameStatus.status === 'not-started' ? (
                            <p className="animate fade delay-4" >Aguardando início do jogo...</p>
                        ) : gameStatus.status === 'started' ? (
                            <p className="animate fade delay-4" >Jogo iniciado! Boa sorte.</p>
                        ) : gameStatus.status === 'finished' ? (
                            <>
                                {loading ? (
                                    <AnimatePresence>
                                        {loading && (
                                            <motion.div
                                                className="loading-screen-lead"
                                                initial="hidden"
                                                animate="visible"
                                                exit="exit"
                                                variants={loadingScreenVariants}
                                                transition={{ duration: 1.4 }}  // Adjust the transition timing as needed
                                            >
                                                <div className="loading-content">
                                                    <div class="text_shadows">
                                                        {gameStatus.gameResult === 'victory' ? 'VITÓRIA' :
                                                            gameStatus.gameResult === 'defeat' ? 'DERROTA' :
                                                                'CARREGANDO...'}
                                                    </div>


                                                </div>
                                            </motion.div>
                                        )}
                                    </AnimatePresence>
                                ) : (
                                    <motion.div className="" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 1.4 } }}>
                                        <p className="animate fade delay-4 title-finish" >Partida Encerrada!</p>
                                        <p className="animate fade delay-6 pares-games" >Pares encontrados <br /> <bold style={{ color: '#FD7A49' }}> {gameStatus.pairsFound}</bold></p>

                                        <p className="animate fade delay-5 duration-game" >Duração do jogo <br /> <bold style={{ color: '#FD7A49' }}>{gameStatus.duration} segundos</bold></p>
                                        <button onClick={handleLeaveQueue}>Sair da fila</button>
                                        <span>*Você perderá seu pin</span>
                                    </motion.div>
                                )}
                            </>
                        ) : null}

                    </div>
                ) : (
                    <>
                        <p className="animate fade delay-6">Preencha os dados abaixo para liberar o jogo</p>
                        <ToastContainer
                            position="top-center"
                            autoClose={5000}
                            hideProgressBar={false}
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="dark"
                        />
                        <div className="form-group animate fade delay-7">
                            <input
                                type="text"
                                placeholder="Nome"
                                value={name}
                                onChange={handleNameChange}
                                maxLength={30}
                            />
                        </div>
                        <div className="form-group animate fade delay-8">
                            <input
                                type="email"
                                placeholder="Email da empresa"
                                value={email}
                                onChange={handleEmailChange}
                                minLength={5}
                            />
                            {emailError && <p className="error-message">{emailError}</p>}
                        </div>
                        <div className="form-group animate fade delay-9">
                            <input
                                type="text"
                                placeholder="Empresa"
                                value={company}
                                onChange={handleCompanyChange}
                                maxLength={30}
                            />
                        </div>
                        <div className="form-group animate fade delay-10">
                            <p className="question">Sua empresa realiza Programas Trainee?</p>
                            <div className="radio-group">
                                <label className="label-radio">
                                    <input
                                        type="radio"
                                        value="Sim"
                                        checked={companyTraineeProgram === "Sim"}
                                        onChange={handleTraineeProgramChange}
                                    />
                                    Sim
                                </label>
                                <label className="label-radio">
                                    <input
                                        type="radio"
                                        value="Não"
                                        checked={companyTraineeProgram === "Não"}
                                        onChange={handleTraineeProgramChange}
                                    />
                                    Não
                                </label>
                            </div>
                        </div>
                        <button onClick={handleStartGame} className="animate pop delay-11">ENVIAR</button>
                    </>
                )}
            </motion.div>
        </motion.div>
    );
}

export default LeadCapture;

import React, { useEffect } from 'react';
import "../VictoryDefeat.css";

import First from '../../../assets/frst.svg';
import Plus from '../../../assets/plus.svg';
import Vesjobs from '../../../assets/vesjobs.svg';
import victoryGif from '../../../assets/victory.webp';

const VictoryModal = ({ userName, onClose }) => {
  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.reload(); 
    }, 10000); 

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="victory-modal-overlay">
      <div className="victory-modal animate fade delay-1">
        <img src={victoryGif} alt="Victory" className="victory-gif animate fade delay-3" />
        <h1 className='victory-title animate fade delay-4'>{userName}!</h1>
        <p className='victory-text animate fade delay-5'>Você dominou todas as vantagens de fazer um programa conosco.</p>
        <div className="logo-container">
          <img src={First} alt="First logo" className="logo-part animate pop delay-6" />
          <img src={Plus} alt="Plus logo" className="logo-plus animate pop delay-7" />
          <img src={Vesjobs} alt="Vesjobs logo" className="logo-part animate pop delay-8" />
        </div>
      </div>
    </div>
  );
};

export default VictoryModal;

import React, { useState, useEffect } from 'react';
import './GameBoard.css';
import SingleCard from '../../components/Card/Card';
import { cardImages } from '../../components/Card/cardImages';
import VictoryModal from '../../components/Modals/Victory/VictoryModal';
import DefeatModal from '../../components/Modals/Defeat/DefeatModal';

import First from '../../assets/frst.svg';
import Plus from '../../assets/plus.svg';
import Vesjobs from '../../assets/vesjobs.svg';

import { doc, updateDoc } from 'firebase/firestore';
import { FIRESTORE_DB } from '../../firebaseConfig';
import { motion } from 'framer-motion';

const GameBoard = ({ leadData }) => {
    const [cards, setCards] = useState([]);
    const [turns, setTurns] = useState(12);
    const [choiceOne, setChoiceOne] = useState(null);
    const [choiceTwo, setChoiceTwo] = useState(null);
    const [disabled, setDisabled] = useState(false);
    const [sequenceCount, setSequenceCount] = useState(0);
    const [countingPoints, setCountingPoints] = useState(false);
    const [rainbowText, setRainbowText] = useState(false);
    const [hasStarted, setHasStarted] = useState(false);
    const [pairsFound, setPairsFound] = useState(0);
    const [gameStartTime, setGameStartTime] = useState(null); // Inicializa como null
    const [gameDuration, setGameDuration] = useState(0); // Inicializa como 0

    const [victory, setVictory] = useState(false);
    const [defeat, setDefeat] = useState(false);
    const [defeatReason, setDefeatReason] = useState('');

    const [status, setStatus] = useState('not-started')
    const [isLoading, setIsLoading] = useState(true);

    const [isInitialFlip, setIsInitialFlip] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 5000);  
    
        return () => clearTimeout(timer);
    }, []);

    const shuffleVariants = {
        hidden: { scale: 0.5, opacity: 0, y: 50 },
        visible: (i) => ({
            scale: 1,
            opacity: 1,
            y: 0,
            transition: {
                delay: i * 0.1,
                type: 'spring',
                stiffness: 120,  // Menor rigidez para uma mola mais suave
                damping: 15       // Amortecimento mais alto para evitar oscilações excessivas
            }
        })
    };
    
      
    const handleCloseModal = () => {
        setVictory(false);
        setDefeat(false);
    };

    const updateGameResult = async (result) => {
        if (!leadData || !leadData.id) {
            console.error('leadData or leadData.id is undefined');
            return;
        }

        try {
            const leadDocRef = doc(FIRESTORE_DB, 'leads', leadData.id);
            await updateDoc(leadDocRef, {
                gameResult: result,
                turnsLeft: turns,
                pairsFound: pairsFound,
                gameStartTime: {
                    date: gameStartTime.date,
                    time: gameStartTime.time
                },
                duration: gameDuration, // Salvando a duração do jogo no Firebase
                status: status,
            });
        } catch (error) {
            console.error('Error updating game result: ', error);
        }
    };


    useEffect(() => {
        const handleStorageChange = (e) => {
            if (e.key === 'gameSettings') {
                const newSettings = JSON.parse(e.newValue);
                setTurns(newSettings.turns);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [turns]);

    useEffect(() => {
        const savedSettings = JSON.parse(localStorage.getItem('gameSettings'));
        if (savedSettings) {
            setTurns(savedSettings.turns);
        }
    }, []);

    const handleChoice = (card) => {
        setHasStarted(true);
        if (!choiceOne || !choiceTwo) {
            if (!choiceOne || (choiceOne && choiceOne.id !== card.id)) {
                setDisabled(true);
                choiceOne ? setChoiceTwo(card) : setChoiceOne(card);
            }
        }
    };

    
    useEffect(() => {
        if (leadData) {
            shuffleCards();
            setTurns(12);
            updateDoc(doc(FIRESTORE_DB, 'leads', leadData.id), { status: 'started' });
            setVictory(false);
            setDefeat(false);
            setSequenceCount(0);
            setCountingPoints(false);
            setPairsFound(0);
            const now = new Date();
            setGameStartTime({
                date: `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`,
                time: `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`
            });
        }
    }, [leadData]);

    const shuffleCards = () => {
        // Embaralha as cartas e atribui um identificador único a cada uma
        const shuffledCards = [...cardImages, ...cardImages]
            .sort(() => Math.random() - 0.5)
            .map((card) => ({ ...card, id: Math.random() }));
    
        setCards(shuffledCards);
        setIsInitialFlip(true); // Inicialmente, todas as cartas são mostradas
    
        // Define um timeout para esconder as cartas após 5 segundos
        const timeout = setTimeout(() => {
            setIsInitialFlip(false);
        }, 10000);
    
        // Resto da inicialização do jogo
        setTurns(12);
        setVictory(false);
        setDefeat(false);
        setSequenceCount(0);
        setCountingPoints(false);
        setPairsFound(0);
        const now = new Date();
        setGameStartTime({
            date: `${now.getDate()}/${now.getMonth() + 1}/${now.getFullYear()}`,
            time: `${now.getHours()}:${String(now.getMinutes()).padStart(2, '0')}`
        });
    
        // Retorna uma função para limpar o timeout quando o componente for desmontado
        // ou quando a função shuffleCards for chamada novamente
        return () => clearTimeout(timeout);
    };
    

    useEffect(() => {
        if (sequenceCount > 3) {
            setRainbowText(true);

            const timeoutId = setTimeout(() => {
                setRainbowText(false);
            }, 2000);

            return () => clearTimeout(timeoutId);
        }
    }, [sequenceCount]);

    useEffect(() => {
        if (choiceOne && choiceTwo) {
            setDisabled(true);

            if (choiceOne.src === choiceTwo.src) {
                setCards((prevCards) => {
                    return prevCards.map((card) => {
                        if (card.src === choiceOne.src) {
                            return { ...card, matched: true };
                        } else {
                            return card;
                        }
                    });
                });

                setPairsFound(pairsFound + 1);
                resetTurn();
            } else {
                setTimeout(() => resetTurn(), 1000);
            }
        }
    }, [choiceOne, choiceTwo]);

    useEffect( () => {
        if (hasStarted && gameStartTime) {
            
            const interval = setInterval(() => {
                setGameDuration((prevDuration) => prevDuration + 1); // Incrementa a cada segundo
            }, 1000); // Atualiza a cada segundo

            return () => clearInterval(interval); // Limpa o intervalo quando o componente for desmontado
        }
    }, [hasStarted, gameStartTime]);

    useEffect(() => {
        if (hasStarted) {
            const unmatchedCards = cards.filter((card) => !card.matched);
            
            setStatus('finished')
            if (unmatchedCards.length === 0 && cards.length > 0) {
                handleGameOver(true);
            } else if (turns === 0) {
                setDefeatReason('turns');
                handleGameOver(false);
                
            }
        }
    }, [cards, turns, hasStarted, status]);

    const handleGameOver = (isWinner) => {
        if (isWinner) {
            setVictory(true);
            updateGameResult('victory');
        } else {
            setDefeat(true);
            updateGameResult('defeat');
        }
    };

    const handleRestart = () => {
        setVictory(false);
        setDefeat(false);
        setSequenceCount(0);
        shuffleCards();
        setHasStarted(false);
        setGameDuration(0); // Resetando a duração do jogo
    };

    const resetTurn = () => {
        setChoiceOne(null);
        setChoiceTwo(null);
        setTurns((prevTurns) => prevTurns - 1);
        setDisabled(false);
    };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        return `${hours > 0 ? hours + 'h ' : ''}${minutes > 0 ? minutes + 'm ' : ''}${seconds}s`;
    };
    return (
        <div className="App">
              {isLoading && (
            <div className="loading-screen">
                <div className='loader'></div>
            </div>
        )}
          {!isLoading && (
            <>
            <header className="header">
                <p className="lead-name">{leadData.name}</p>
                <p className="player-turns"><span className='animate pop delay-3'>Jogadas:</span><span className='animate pop delay-4'>{turns}</span></p>
              {/*   {gameStartTime && (
                    <p className="game-start-time">Início do jogo: {gameStartTime.date} às {gameStartTime.time}</p>
                )}
                <p className="pairs-found">Pares encontrados: {pairsFound}</p>
                <p className="game-duration">Duração do jogo: {formatDuration(gameDuration)}</p> 
            */}
            </header>
            <div className="game-container">
            <div className="card-grid">
  {cards.map((card, index) => (
    <motion.div
      key={card.id}
      custom={index}
      variants={shuffleVariants}
      initial="hidden"
      animate="visible"
      className={`card ${card.matched ? 'matched-animation' : ''}`}

    >
      <SingleCard
        card={card}
        handleChoice={handleChoice}
        flipped={card === choiceOne || card === choiceTwo || card.matched || isInitialFlip}
      />
    </motion.div>
  ))}
</div>

            </div>
            <footer className="footer">
                <div className="logo-container">
                    <img src={First} alt="First logo" className="game-logo-part" />
                    <img src={Plus} alt="Plus logo" className="game-logo-plus" />
                    <img src={Vesjobs} alt="Vesjobs logo" className="game-logo-part" />
                </div>
            </footer>
            {victory && !countingPoints && (
                <VictoryModal
                    onRestart={handleRestart}
                    onClose={handleCloseModal}
                    turns={turns}
                    userName={leadData.name}
                />
            )}
            {defeat && (
                <DefeatModal
                    onRestart={handleRestart}
                    onClose={handleCloseModal}
                    defeatReason={defeatReason}
                    userName={leadData.name}
                />
            )}
                </>
        )}
        </div>
    );
};

export default GameBoard;

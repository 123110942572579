export const cardImages = [
    { src: '/img/card1.jpg', matched: false },
    { src: '/img/card2.jpg', matched: false },
    { src: '/img/card3.jpg', matched: false },
    { src: '/img/card4.jpg', matched: false },
    { src: '/img/card5.jpg', matched: false },
    { src: '/img/card6.jpg', matched: false },
    { src: '/img/card7.jpg', matched: false },
    { src: '/img/card8.jpg', matched: false },

    //para adicionar mais cartas vamos precisar das artes 
];
import React, { useEffect } from 'react';
import "../VictoryDefeat.css";

import First from '../../../assets/frst.svg';
import Plus from '../../../assets/plus.svg';
import Vesjobs from '../../../assets/vesjobs.svg';
import defeatGif from '../../../assets/defeat.webp';

const DefeatModal = ({ userName, onClose }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.reload();
        }, 10000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className="defeat-modal-overlay">
            <div className="defeat-modal animate fade delay-1">
                <img src={defeatGif} alt="Defeat" className="defeat-gif animate fade delay-3" />
                <h1 className='defeat-title  animate fade delay-4'>{userName}!</h1>
                <p className='defeat-text animate fade delay-5'>Ops! Parece que algumas vantagens ainda ficaram escondidas...</p>
                <div className="logo-container">
          <img src={First} alt="First logo" className="logo-part animate pop delay-6" />
          <img src={Plus} alt="Plus logo" className="logo-plus animate pop delay-7" />
          <img src={Vesjobs} alt="Vesjobs logo" className="logo-part animate pop delay-8" />
        </div>
            </div>
        </div>
    );
};

export default DefeatModal;
